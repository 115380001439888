<template>
	<div style="color: black;" :class=" mainNavIsHidden ? 'systemInfo' : 'gradient-background'">
		<h1>Diagnostic Data</h1>
		<div class="diagnostics">
			<p><strong>Environment Variables:</strong> {{ environmentVariables }}</p>
			<p>{{ playerData }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "SystemInfo",
	props: {
		playerState: Object,
		mainNavIsHidden: Boolean,
	},
	data() {
		return {
			playerData: JSON.stringify(this.playerState, null, "\t"),
			environmentVariables: process.env,
		};
	},
	watch: {
		playerState: {
			handler() {
				this.updateSysInfo();
			},
			deep: true,
		},
		systemSettings: {
			handler() {
				this.updateSysInfo();
			},
			deep: true,
		},
	},
	methods: {
		updateSysInfo() {
			this.playerData = JSON.stringify(this.playerState, null, "\t");
			this.systemSettingsString = JSON.stringify(this.systemSettings, null, "\t");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	overflow: hidden;
}
h1 {
	text-align: center;
}
/* .systemInfo {
	position: relative;
	margin-left: 20%;
	padding: 15px 0;
	background: url("../assets/banner001.jpg") center no-repeat;
	background-size: cover;
	overflow: hidden auto;
	height: calc(100vh - 160px);
} */
.diagnostics {
	/* position: absolute; */
	display: flex;
	flex-flow: column;
	/* top: 0; */
	width: 80%;
	margin: 15px auto 45px;
	padding: 30px;
	background-color: #6305C9;
	border-radius: 8px;
	color: white;
}
.diagnostics p,
.diagnostics pre {
	font-family: "Courier New", monospace;
	overflow-wrap: anywhere;
	white-space: pre-wrap;
}

.systemInfo {
	margin-top: 12.5vh;
}

#main-nav-hidden {
	height: 230vh;
}
</style>
